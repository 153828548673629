import React, { useState, useEffect } from "react";
import isMobile from "is-mobile";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Collapse } from "@blueprintjs/core";
import Link from "next/link";
import Image from "next/image";

import Login from "./dialog/Login/Login";
import Signup from "./dialog/Signup/Signup";
import ResetPassword from "./dialog/ResetPassword/ResetPassword";
import NewPassword from "./dialog/NewPassword/NewPassword";
import { useAuth, logout } from "./hook/useAuth";
import Logo from "../imgs/logo.svg";
import PersonLight from "../imgs/personLight.svg";
import { redirectUrl } from "./config/endPoints";
import Cookies from "js-cookie";

import MenuIcon from "../imgs/menuIcon.svg";
import Cross from "../imgs/cross.svg";
import useWindowDimensions from "./utils/ScreenSize";

const Header = (props) => {
  const { width } = useWindowDimensions();
  const [mbNavOpen, setMbNavOpen] = useState(false);
  // const [showLoginModal,setShowLoginModal] = useState(false);
  // const [showSignupModal,setShowSignupModal] = useState(false);
  // const [showResetModal,setShowResetModal] = useState(false);
  // const [showNewPasswordModal,setShowNewPasswordModal] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(false);
  const {
    token,
    user,
    isShowLogin,
    isShowSignup,
    isShowNewPassword,
    isShowResetPassword,
    resetPasswordCode,
    setShowLogin,
    setShowSignup,
    setShowNewPassword,
    setShowResetPassword,
  } = useAuth();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [haveToken, setHaveToken] = useState(false);

  useEffect(() => {
    getCookiesData();
  }, []);

  const getCookiesData = () => {
    if (typeof window !== "undefined") {
      if (Cookies.get("token")) {
        setHaveToken(true);
      }
    }
  };

  const resetPasswordProc = () => {
    setShowLogin(false);
    setShowResetPassword(true);
  };
  const registerSuccessProc = () => {
    setShowSignup(false);
  };

  useEffect(() => {
    if (user) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [user]);

  /* Track a page view */
  //analytics.page()

  return (
    <div className="oldHeader">
      <nav className="navbar navbar-expand-lg info-color sticky-top">
        <div className="nav_container">
          <div className="col-7 col-sm-4 col-md-4 col-lg-4 nav_cell">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link href="/" title="Home Page" passHref>
                <a>
                  <Image src={Logo} alt="Mendelgen Logo" className="logo" width={190} />
                </a>
              </Link>
            </div>
          </div>
          <div
            className="col-12 col-sm-6 col-md-4 col-lg-4 nav_cell login_part"
            style={{ display: width > 800 ? "flex" : "none" }}
          >
            {!isUserLoggedIn && (
              <div style={{ display: "block" }}>
                <Link href={"/docs"} title="Open Documentation" passHref>
                  <button id="signin">User Guide</button>
                </Link>
                {!haveToken && (
                  <button
                    id="signin"
                    className="sign"
                    onClick={() => {
                      setShowLogin(true);
                    }}
                  >
                    Sign In
                  </button>
                )}

                <button
                  id="signup"
                  className="sign"
                  onClick={() => {
                    if (haveToken) {
                      document.location.href = redirectUrl;
                    } else {
                      setShowSignup(true);
                    }
                  }}
                  style={{
                    minWidth: haveToken ? 120 : "unset",
                    marginLeft: haveToken ? 15 : "unset",
                  }}
                >
                  {haveToken ? "Go To App" : "Sign Up"}
                </button>
              </div>
            )}
            {isUserLoggedIn && !isMobile() && (
              <div
                className="col-6 col-sm-6 login_part right"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: isMobile() ? "0" : "unset",
                  flexDirection: isMobile() ? "column" : "row",
                }}
              >
                <div style={{ display: "block" }}>
                  <div
                    onClick={(e) => {
                      setShowMenuBar(!showMenuBar);
                    }}
                    className="toolbarBtn"
                    style={{
                      width: "35px",
                      height: "35px",
                      marginLeft: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Image style={{ margin: "auto" }} src={PersonLight} alt="profile" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: width < 800 ? "flex" : "none", marginRight:"10px" }}>
            {!mbNavOpen && (
              <div className="navbar__actions--menuIcon" onClick={(e) => setMbNavOpen(true)}>
                <Image src={MenuIcon} alt="Open Menu" />
              </div>
            )}

            {mbNavOpen && (
              <div
                className="navbar__actions--menuIcon navbar__actions--menuIcon--cross"
                onClick={(e) => setMbNavOpen(false)}
              >
                <Image src={Cross} alt="Close Menu" className="cross" />
              </div>
            )}
          </div>
        </div>
        <div className="newHeader">
          <Collapse isOpen={mbNavOpen}>
            <div className="navbar__actions navbar__actions--sm">
              <Link href="/docs" title="Open Documentation" passHref>
                <a>
                  <div className="mbNavLink">
                    <span className="navLink">User Guide</span>
                  </div>
                </a>
              </Link>
              <button
                id="signin"
                className="sign"
                style={{ display: "block", paddingBottom: "40px" }}
                onClick={() => {
                  if (haveToken) {
                    document.location.href = redirectUrl;
                  } else {
                    setShowLogin(true);
                  }
                }}
              >
                <div className="mbNavLink">
                  <span className="navLink">{haveToken ? "Go To App" : "Sign In"}</span>
                </div>
              </button>
              {!haveToken ? (
                <Button
                  id="signup"
                  className="sign"
                  style={{ display: "block"}}
                  onClick={() => {
                    setShowSignup(true);
                  }}
                >
                  <span className="navLink">Sign Up</span>
                </Button>
              ) : null}
            </div>
          </Collapse>
        </div>
      </nav>

      <Login
        showModal={isShowLogin}
        closeProc={() => {
          setShowLogin(false);
        }}
        resetPassword={resetPasswordProc}
      />
      <Signup
        showModal={isShowSignup}
        closeProc={() => {
          setShowSignup(false);
        }}
        successProc={registerSuccessProc}
      />
      <ResetPassword
        showModal={isShowResetPassword}
        closeProc={() => {
          setShowResetPassword(false);
        }}
      />
      <NewPassword
        showModal={isShowNewPassword}
        closeProc={() => {
          props.history.push("/");
          setShowNewPassword(false, "");
        }}
        resetCode={resetPasswordCode}
        successProc={() => {
          setShowNewPassword(false, "");
          props.history.push("/");
        }}
      />
    </div>
  );
};

export default Header;
