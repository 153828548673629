import React from 'react';
import OldHeader from '../components/OldHeader';
import Footer from '../components/Footer';

import Link from 'next/link'


const FourOhFour = () => {
  return <>
  <OldHeader />
  <div className="template">

  <section className="headline">
          <div className="head">
            <h1>The link may have moved</h1>
          </div>
          <div className="subHead">
            <p>Perhaps you are looking for one of the pages below?</p>
          </div>
        </section>

    <section className="body">
    <div className="text">
        <Link href="/">
        <a>
            Home Page
        </a>
        </Link>
        <br />
        <Link href="/docs">
        <a>
        Introduction to Mendelgen
        </a>
        </Link>
        <br />
        <Link href="/controls">
        <a>
        Controls Overview
        </a>
        </Link>
        <br />
        <Link href="/hotkeys">
        <a>
        Hotkeys
        </a>
        </Link>
        <br />
        <Link href="/preferences">
        <a>
        Workspace and Viewing Preferences
        </a>
        </Link>
        <br />
        <Link href="/vector-wizard">
        <a>
            Vector Wizard
        </a>
        </Link>
        <br />
        <Link href="/sequence">
        <a>
        Working With Your Sequence
        </a>
        </Link>
        <br />
        <Link href="/editing">
        <a>
        Using Mendelgen for DNA Editing (Advanced Features)
        </a>
        </Link>
        <br />
        <Link href="/settings">
        <a>
        Advanced Settings
        </a>
        </Link>
        <br />
        <Link href="/casestudy">
        <a>
        Case Study: Cloning into lentiCRISPR vector
        </a>
        </Link>
        <br />
        <Link href="/restriction-enzyme-cloning">
        <a>
        Restriction Enzyme Cloning
        </a>
        </Link>
        <br />
        <Link href="/gibson-assembly">
        <a>
        Gibson Assembly
        </a>
        </Link>
        <br />
        <Link href="/ta-cloning">
        <a>
        TA Cloning
        </a>
        </Link>
      </div>
      </section>
      </div>
      <Footer /> 
  </>
  
}

export default FourOhFour;